import React from 'react';
import {MainPage} from "../header/header";
import {Outlet} from "react-router-dom";
import {Footer} from "../footer/footer";
import './layout.scss';

export const Layout = () => {
    return (
        <div className="container">
            {/*<header>*/}
            {/*    <MainPage/>*/}
            {/*</header>*/}

            <main>
                <Outlet />
            </main>
            {/*<footer>*/}
            {/*    <Footer />*/}
            {/*</footer>*/}
        </div>
    );
};

export default Layout;