import React from 'react';
import style from './home.module.scss';
import {FaRegPaperPlane} from 'react-icons/fa';
import {SubmitHandler, useForm} from "react-hook-form";
import {BASE_URL} from "../../constants";

interface Form {
    team_number: number,
    team_data_1: string,
    team_data_2: string,
    team_data_3: string,
    team_data_4: string,
}

const Home = () => {
    const {register, handleSubmit, reset, formState:{errors}} = useForm<Form>();

    const submit: SubmitHandler<Form> = data => {
            fetch(BASE_URL + 'users', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
    }

    return (
        <div className='form-container'>
            <form >
                <h1 className={style.head}>Форма</h1>
                <input type="number" placeholder="Номер команды" {...register('team_number')}/>

                <textarea  placeholder='Напишите' {...register('team_data_1')}></textarea>

                <textarea  placeholder='Напишите' {...register('team_data_2')}></textarea>

                <textarea  placeholder='Напишите' {...register('team_data_3')}></textarea>

                <textarea  placeholder='Напишите' {...register('team_data_4')}></textarea>

                <button className={style.btn} onClick={handleSubmit(submit)}>
                    <FaRegPaperPlane />
                    Отправить
                </button>
            </form>
        </div>
    );
};

export default Home;